import React from 'react';
import './App.css';

// React Router
import { 
  createHashRouter,
  Route,
  createRoutesFromElements,
  RouterProvider
} from 'react-router-dom';


// Components
import Navbar from './components/navbar/Navbar';

// Pages
import Home from './pages/home/Home';
import About from './pages/about/About';
import Team from './pages/team/Team';
import Career from './pages/career/Career';
import Partnership from './pages/products/Products';
import Achievements from './pages/Achievements/Achievements';
import Certificate from './pages/Certificate/certificate';
import InnerProducts from './pages/innerproducts/InnerProducts';
import NotFound from './pages/notfound/NotFound';


const router = createHashRouter(
  createRoutesFromElements(
    <Route path="/" element={<Navbar />}>
      <Route path="/" element={<Home />} />
      <Route path="/about" element={<About />} />
      <Route path='/team' element={<Team />} />
      {/* <Route path='/career' element={<Career />} /> */}
      <Route path='/products' element={<Partnership />} />
      <Route path='/products/:id' element={<InnerProducts />} />
      <Route path='/Achievements' element={<Achievements />} />
<Route path='/mandatorydisclosures' element={<Certificate />} />
      <Route path="*" element={<NotFound />} />
    </Route>
  )
)

export default function App() {
  return (
    <RouterProvider router={router} />
  );
}
