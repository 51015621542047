import React from 'react';
import './team.css';

// Framer Motion
import {motion as m} from 'framer-motion';
import {SiGmail} from "react-icons/si"
import { icons } from 'react-icons';

const Team = () => {
  return (
    <m.div
      className='team'
      initial={{opacity: 0}}
      animate={{opacity: 1}}
      exit={{opacity: 0}}
      transition={{duration: 1, ease: "linear"}}
    >
      <h3>Founder and Co-founder of Sweekriti Asset</h3>
      <div className="whyus">
            <div className="row-whyus">
                <div className="whyus-card">
                    <div className="thumb-whyus-image">
                        <img src="https://ik.imagekit.io/99djpd8k3/1681279895347.jpg?updatedAt=1681412298158" alt="" />
                    </div>
                    <div className="desc-card">
                        <h2 class='paraContent'>Dr. TUSHARIKA SINHA BANERJEE  <a href="mailto:tusharika@sweekriti-asset.in" style={{margin:"20px",marginBottom:"50px"}}><SiGmail/> </a> </h2>
                        <h5 class='paraContent'>Founder & Director-Sweekriti Asset(Main Division-R&D)</h5>
                        <p class='paraContent'>The aim is to collectively bring  ideas, individuals and eventually those sections of society who have been discarded on grounds of being difficult, different or unusual.The main division of Sweekriti Asset  is Research and Development. There is no tool better than education that will empower us most , is a well known fact ,however this needs to be re-thought and re-exercised very frequently.  Research  completion can be marked if development of research is allowed and probably is the only option to allow the explosion of all the education that one has acquired.Research is the one point conclusion to create and develop entities.Problems are infinite, unstoppable and so are its solutions! 
                       </p>
                    </div>
                </div>
                <div className="whyus-card">
                    <div className="thumb-whyus-image">
                        <img src="https://sweekriti-asset1111111111111.vercel.app/_next/image?url=%2Fimages%2Fmentors%2Fcofounder.jpg&w=640&q=75" alt="" />
                    </div>
                    <div className="desc-card">
                        <h2 class='paraContent'>Dr. ARTI HADAP  <a href="mailto:arti@sweekriti-asset.in" style={{margin:"20px",marginBottom:"50px"}}><SiGmail/> </a> </h2>
                        <h5 class='paraContent'>Co-Founder & CEO-Sweekriti Asset(Main Division-R&D)</h5>
                        <p class='paraContent'>We the Indians are facing the need of a turning point in the global economy and society that will change the way we plan to earn. We are extremely knowledgeable slaves which limits themselves to work on advices and limits the thoughts. My vision as CEO of SWEEKRITI ASSET- standing with the standalones, is to make a platform to understand the current needs and demands faced by society and give a customized sustainable technical solution to it. Solutions need the multiple, innovative, N-dimensional thinking for that Sweekriti Asset offers the real platform to like-minded people who really think the problems and multidimensional limitless thoughts to give solutions.
</p>
                    </div>
                </div>
       
            </div>
        </div>
    </m.div>
  )
}

export default Team;