import React from 'react';
import './about.css';

// Framer Motion
import {motion as m} from 'framer-motion';

// React Slick
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

// Image
import Image1 from './../../img/about/about-img1.jpg';
import Image2 from './../../img/about/about-img2.jpg';
import Image3 from './../../img/about/about-img3.jpg';
import Image4 from './../../img/about/about-img4.jpg';
import Image5 from './../../img/about/about-img5.jpg';
import Image6 from './../../img/about/about-img6.jpg';

const SlickArrowLeft = ({ style }) => (
  <div
    style={{ ...style, display: "none"}}
  />
);

const SlickArrowRight = ({ style }) => (
    <div
      style={{ ...style, display: "none"}}
    />
);

const About = () => {
  const settings = {
    dots: false,
    fade: true,
    infinite: true,
    prevArrow: <SlickArrowLeft />,
    nextArrow: <SlickArrowRight />,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 1500,
    cssEase: "linear"
  };

  return (
      <m.div
        className='about'
        initial={{opacity: 0}}
        animate={{opacity: 1}}
        exit={{opacity: 0}}
        transition={{duration: 1, ease: "linear"}}
      >
        <div className='about-heading'>
          <h3>About Sweekriti Asset</h3>
          <p>Sweekriti Asset Is Maharashtra based Research and Development company registered dated 2022-01-10 ,on Ministry of Corporate Affairs with allocated Registration no ABA-1704.
The main division of this firm is Research & Development.The company aims to take ahead idea/concepts/theoritical knowledge so as to provide solutions to many problems and possibly every problem by keeping the dimensions open in all directions.</p>
<h3>Vision of Sweekriti Asset</h3>
<p>1. Sweekriti Asset has been established with a vision to create a platform for Research and Development so that the complete purpose of education is achieved. <br/>
2. To responsibly provide approachable solutions to the much needed outcomes.<br/>
3. To allow the self- sustainable mode of development to the underprivileged entities, eventually creating more solution providers of the same.<br/>
4. To provide space for individuals to think, feel, exercise their minds or thoughts, fearlessly so that the growth process which starts from self may happen.</p>
<h3>Mision of Sweekriti Asset</h3>
<p>To create grow and establish reliable,adequate solutions through analysis , understanding and self-belief  so as to be able to apply the acquired knowledge in most needed dimensions.</p>
        </div>
       
        <div className='row-about'>
          <Slider {...settings}>
            {/* <div className='image-about'>
              <img src="https://ik.imagekit.io/99djpd8k3/WhatsApp_Image_2023-03-07_at_10.59.35_PM.jpeg?ik-sdk-version=javascript-1.4.3&updatedAt=1678210474428" />
            </div> */}
            {/* <div className='image-about'>
              <img src="https://ik.imagekit.io/99djpd8k3/WhatsApp_Image_2023-03-07_at_11.25.25_PM.jpeg?ik-sdk-version=javascript-1.4.3&updatedAt=1678211912825" />
            </div>
            <div className='image-about'>
              <img src="https://ik.imagekit.io/99djpd8k3/WhatsApp_Image_2023-03-07_at_11.26.18_PM.jpeg?ik-sdk-version=javascript-1.4.3&updatedAt=1678211946283" />
            </div> */}
            <div className='image-about'>
              <img src="https://ik.imagekit.io/99djpd8k3/WhatsApp_Image_2023-03-08_at_5.45.02_AM.jpeg?ik-sdk-version=javascript-1.4.3&updatedAt=1678515788099" />
            </div>
            <div className='image-about'>
              <img src="https://ik.imagekit.io/99djpd8k3/WhatsApp%20Image%202023-07-27%20at%2010.44.03%20PM%20(1).jpeg?updatedAt=1690606734935" />
            </div>
            <div className='image-about'>
              <img src="https://ik.imagekit.io/99djpd8k3/WhatsApp%20Image%202023-07-27%20at%209.55.33%20PM.jpeg?updatedAt=1690606734932" />
            </div>
            <div className='image-about'>
              <img src="https://ik.imagekit.io/99djpd8k3/WhatsApp%20Image%202023-07-27%20at%2010.44.03%20PM.jpeg?updatedAt=1690606735019" />
            </div>
            <div className='image-about'>
              <img src="https://ik.imagekit.io/99djpd8k3/mushroom%20garden.webp?updatedAt=1690606836188" />
            </div>
            <div className='image-about'>
              <img src="https://ik.imagekit.io/99djpd8k3/mushroom%20garden%202.webp?updatedAt=1690606835770" />
            </div>
            <div className='image-about'>
              <img src="https://ik.imagekit.io/99djpd8k3/mushrooms.webp?updatedAt=1690606836159" />
            </div>

            <div className='image-about'>
              <img src="https://ik.imagekit.io/99djpd8k3/WhatsApp%20Image%202023-12-20%20at%2010.09.27%20AM.jpeg?updatedAt=1703048710855" />
            </div>
            <div className='image-about'>
              <img src="https://ik.imagekit.io/99djpd8k3/WhatsApp%20Image%202023-12-20%20at%2010.10.25%20AM.jpeg?updatedAt=1703048710959" />
            </div>





            {/* <div className='image-about image5'>
              <img src={Image5} />
            </div>
            <div className='image-about'>
              <img src={Image6} />
            </div> */}
          </Slider>
        </div>
      </m.div>
  )
}

export default About;