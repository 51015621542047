import Image1 from './../../img/cases/1.jpg';
import Image2 from './../../img/cases/2.jpg';
import Image3 from './../../img/cases/3.jpg';
import Image4 from './../../img/cases/4.jpg';
import Image5 from './../../img/cases/5.jpg';

export default [
    {
        id: 1,
        img:"https://ik.imagekit.io/99djpd8k3/20230319_163701_0000.png?updatedAt=1679410545547",
        title: "JEEVAN",
        quote:"For step Forward with Saving Water",
        desc: "We offer a solution that helps villages solve the problem of acute water scarcity. Our automated water pumping system provides villages with an easy and cost-effective way to access water.This system will drastically reduce the time and effort it takes to pump water, providing villagers with easy access to clean water. ",
       section: "Gaon"
    },
    {
        id: 2,
        img: "https://ik.imagekit.io/99djpd8k3/RECO.jpg?updatedAt=1679412204155",
        title: "RENEWABLE ECOsystem ",
        quote:"Beyond just Efficiency",
        desc: "RECO provides an innovative 0/Zero electricity approach to effectively convert energy from renewable sources such as sun and wind, with minimal user intervention.",
       section: "Power"
    },
    {
        id: 3,
        img: "https://ik.imagekit.io/99djpd8k3/jalupay_1.jpg?updatedAt=1678812283654",
        title: "JALUPAYE",
        quote:"One more step with technology for Water",
        desc: "Do you ever worry about running out of water? With JALUPAYE, that's a thing of the past. Our innovative solution helps you monitor and manage your water levels, so you never have to worry about running out. Our service eliminates the need to manually track and measure, allowing you to fill your tanks automatically with ease and convenience, keeping them full even in metro cities or locations with poor water supply.         ",
       section: "Power"
    },
    {
        id: 4,
        img: "https://ik.imagekit.io/99djpd8k3/250by233.png?updatedAt=1679411137057",
        title: "FARM AUTOMATION",
        quote:"Strengthening Farmers and Strengthening Nation",
        desc: "AUTOFARMS or Farm Automation is a revolutionary solution that helps farmers save time, energy, and money. Our system automatically turns on the irrigation system and fills tanks with water to ensure efficient crop growth. With AUTOFARMS or Farm Automation, farmers can focus less on manual labor and involve selves for different work or be much relaxed mentally and physically",
       section: "Gaon"
    },
    {
        id: 5,
        img: "https://ik.imagekit.io/99djpd8k3/MUSHROOOTA.png?updatedAt=1679412094293",
        title: "Mushroots",
        quote:"One more step with technology for Growing Mushroom",
        desc: "Are you a food lover who is always on the lookout for the freshest and most nutritious ingredients? Look no further than our premium selection of fresh mushrooms! Our mushrooms are carefully grown and harvested to ensure the highest quality and nutritional value.",
       section: "Sparsh"
    },
    {
        id: 6,
        img: "https://ik.imagekit.io/99djpd8k3/Main_Logo.png?updatedAt=1679410290833",
        title: "THE SMART GARDEN",
        quote:"Sparkling Green Smiles",
        desc: "With Greenery, you no longer have to worry about complicated maintenance and spending countless hours caring for your garden. Our automated Greenry system allows you to easily set up and maintain lush, vibrant gardens with minimal effort. With our easy-to-use platform, you can create stunning green spaces with a fraction of the time and money usually spent. ",
       section: "Beauty"
    },
    {
        id: 7,
        img: "https://ik.imagekit.io/99djpd8k3/Leap%20Web.png?updatedAt=1708860317967",
        title: "Leap Web",
        quote:"Learn and Earn as a Professional",
        desc: "Leap Web, a division of Sweekriti Asset LLP, empowers individuals through comprehensive digital training, including social media marketing, website development, and freelancing opportunities, to enhance their earning potential and professional growth.",
       section: "Sparsh"
    },
]
