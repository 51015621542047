import React, { useEffect, useState } from 'react';
import './InnerProducts.css';
// Framer Motion
import { motion as m } from 'framer-motion';

// React Router
import { NavLink, useParams } from 'react-router-dom';

// Data
import dataProducts from '../products/dataProducts';

// Pages
import NotFound from '../notfound/NotFound';


const InnerProducts = () => {
    const { id } = useParams();

    const [data, setData] = useState(null);

    useEffect(() => {
        let data = dataProducts.find((data) => data.id === parseInt(id));

        if (data) {
            setData(data);
        }
    }, []);

    return (
        <m.div
            className="partnership"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 1, ease: "linear" }}
        >
            {data ? (
                <>
                    <NavLink to="/products" className='back-to'>&#8592; Back</NavLink>
                    <div className="thumb-image-partnership">
                        <img src={data.img} alt="image-partnership" />
                    </div>
                    <NavLink style={{color:"orange",marginLeft:"5px",fontSize:"0.88rem"}} to={data.link}>
                        <button>View Booklet </button></NavLink>
                        <NavLink style={{color:"orange",marginLeft:"20px",fontSize:"0.88rem"}} to={data.link1}>
                        <button>View Brochure</button></NavLink>
                        <NavLink style={{color:"orange",marginLeft:"20px",fontSize:"0.88rem"}} to={data.web}>
                        <button>Visit Official Website</button></NavLink>


                    <h3 className='partnership-title'>{data.title}</h3>
                    <p className='partnership-desc'><span>{data.type}</span> | {data.period}</p>

                    <div className="partnership-description">
                        <p>{data.desc[0]}</p>
                        <p>{data.desc[1]}</p>
                        <p>{data.desc[2]}</p>
                        {/* <div className="thumb-image-partnership row">
                        <img  alt="image-partnership" />
                        <img src={data.postimg} alt="image-partnership" />
                    </div> */}
                    <div class="row-partnership1">
  <div class="thumb-image-partnership card-partnership1">
    <img src={data.postimg} style={{width:"100%"}}/>
  </div>
  <div class="thumb-image-partnership card-partnership1">
    <img src={data.postimg1} style={{width:"100%"}}/>
  </div>
  
</div>
{/* <div className='row-partnership1'>
                {CertificateData.map((item, index) => (
            
                        <div className='thumb-image-partnership card-partnership1'>
                            <img src={item.img} alt="image-partnership" />               
                    </div>
                ))}
            </div> */}
                        {/* <ol>
                            <li>{data.desc[3][0]}</li>
                            <li>{data.desc[3][1]}</li>
                            <li>{data.desc[3][2]}</li>
                        </ol>
                        <p>{data.desc[4]}</p>
                        <p>{data.desc[5]}</p>
                        <ul>
                            <li>{data.desc[6][0]}</li>
                            <li>{data.desc[6][1]}</li>
                            <li>{data.desc[6][2]}</li>
                        </ul>
                        <p><span>{data.desc[7]}</span></p> */}
                    </div>
                </>
            ) : (
                <NotFound />
            )}
        </m.div>
    )
}

export default InnerProducts;