import React from 'react';
import './footer.css';

// Icon
import {FiLinkedin } from "react-icons/fi";
import { FiInstagram } from "react-icons/fi";
import { FiPhone } from "react-icons/fi";
import { FiHome } from "react-icons/fi";
import { FiMail } from "react-icons/fi";



const Footer = () => {
    return (
        <footer>
            <div className="row-footer">
                <div className='footer-left-side'>
                    <h2>Contact Us</h2>
                    {/* <p><span><FiPhone /> Phone: </span>+62-21-7251855</p> */}
                    {/* <p><span><FiHome /> Address: </span>Jl. H. Jeni II No. 35, Kebayoran Baru,Jakarta Utara 99210</p> */}
                    <p><span ><FiMail /> Email: </span><a style={{color:"white"}} href="mailto:contact@sweekriti-asset.in">contact@sweekriti-asset.in</a></p>
                </div>
                <div className='footer-right-side'>
                    <div>
                        <a href="linkedin.com/in/sweekriti-asset-8b57b3269/"><FiLinkedin /></a>
                        <a href="https://www.instagram.com/sweekriti_asset/"><FiInstagram /></a>
                    </div>
                </div>
            </div>
            <p className='copyright'>Designed & Developed by <a style={{color:"white"}} href="https://sudarsonhalder.netlify.app/"> Sudarson Halder</a></p>
        </footer>
    )
}

export default Footer;
