import React, { useState } from 'react';
import './navbar.css';

// Router
import { NavLink, Outlet } from 'react-router-dom';

// Image
import Logo from './../../img/nav/logo-nav.png';

// Icon
import { FiMenu } from "react-icons/fi";
import { FiX } from "react-icons/fi";

// Components
import Footer from '../footer/Footer';

const Navbar = () => {
    const [toggle, setToggle] = useState(false);

    const handleToggle = () => {
        setToggle(!toggle);
    }
    const handleLinkClick = () => {
        setToggle(false);
    }
    return (
        <>
            <nav>
                <div className="logo-brand">
                    <img src="https://ik.imagekit.io/99djpd8k3/logo-nav.fd0ceb563c7259ff5e8a.png?updatedAt=1680365907478" alt="" />
                </div>

                <div className="nav-menu" id={toggle ? "active" : ""}>
                    <NavLink onClick={handleLinkClick} to="/">Home</NavLink>
                    <NavLink onClick={handleLinkClick} to="/about">About Us</NavLink>
                    <NavLink onClick={handleLinkClick} to="/team">Team</NavLink>
                    <NavLink onClick={handleLinkClick} to="/products">Products</NavLink>
                    <NavLink onClick={handleLinkClick} to="/achievements">Achievements</NavLink>
                    <NavLink onClick={handleLinkClick} to="/mandatorydisclosures">Mandatory Company Disclosures</NavLink>
                </div>

                <div className="burgers" onClick={handleToggle}>
                    {toggle ? <FiX /> : <FiMenu />}
                </div>
            </nav>
            <Outlet />
            <Footer/>
        </>
    )
}

export default Navbar;
