import React from 'react';


// Framer Motion
import {motion as m} from 'framer-motion';

// React Slick
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

// Image
import Image1 from './../../img/about/about-img1.jpg';
import Image2 from './../../img/about/about-img2.jpg';
import Image3 from './../../img/about/about-img3.jpg';
import Image4 from './../../img/about/about-img4.jpg';
import Image5 from './../../img/about/about-img5.jpg';
import Image6 from './../../img/about/about-img6.jpg';

const SlickArrowLeft = ({ style }) => (
  <div
    style={{ ...style, display: "none"}}
  />
);

const SlickArrowRight = ({ style }) => (
    <div
      style={{ ...style, display: "none"}}
    />
);

const Achievements = () => {
  const settings = {
    dots: false,
    fade: true,
    infinite: true,
    prevArrow: <SlickArrowLeft />,
    nextArrow: <SlickArrowRight />,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    cssEase: "linear"
  };

  return (
      <m.div
        className='about'
        initial={{opacity: 0}}
        animate={{opacity: 1}}
        exit={{opacity: 0}}
        transition={{duration: 1, ease: "linear"}}
      >
        <div className='about-heading'>
          <h3>Achievements</h3>
          <p> The journey to a point so as to be able to stand for Technology,Innovation,Education,Research, Development and especially for all those who stand alone is through encouragements and appreciations which one experiences through their own journeys while also  having shares of many failures,rejections,criticisms,mistakes,insults, <br/>frustrations  and still choosing to move forward.It is thus important to share the glory points and make everyone witness the positive cycles encountered till here which is again very essential for all those  who may be striving substantially to make their own paths.Here is a quick go through of many take aways for the Founders own journey.Inviting everyone ……..for this."Sucess is very Easy while Failing does Require Continuos Efforts"Motivation is the key to "THE DEEP ROOTED DESIRE" which is present inside everyone.Inviting across- For this Motivation
</p>
        </div>
        <m.div
            className='partnership'
            initial={{ y: "25%", opacity: 0 }}
            animate={{ y: "0%", opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 1, ease: "linear" }}
        >
          
            {/* <p className="desc-partnership">The technology ,youth,energy,expertise have combined at Sweekriti Asset to extend solutions and Invitations to every one ,for everyone.
Welcoming everyone to have a look on our products.</p> */}

            <div className='row-partnership1'>
                
            
                        <div className='thumb-image-partnership card-partnership1'>
                            <img src="https://ik.imagekit.io/99djpd8k3/_Professional_Development_Infographic__1_.png?updatedAt=1680281178818" alt="image-partnership" />
                            <h3 className='title-partnership'>Highlights and Achievements By Founder</h3>
                     
                    </div>
             
            </div>
            
        </m.div>
        <div className='row-about'>
          <Slider {...settings}>
          <div className='image-about'>
              <img src="https://ik.imagekit.io/99djpd8k3/WhatsApp%20Image%202023-07-29%20at%2010.54.31%20AM.jpeg?updatedAt=1690608287060" />
            </div>
            <div className='image-about'>
              <img src="https://ik.imagekit.io/99djpd8k3/WhatsApp_Image_2023-03-27_at_11.23.47_PM.jpeg?updatedAt=1679943020740" />
            </div>
            <div className='image-about'>
              <img src="https://ik.imagekit.io/99djpd8k3/WhatsApp_Image_2023-03-26_at_4.41.05_PM.jpeg?updatedAt=1679933434624" />
            </div>
            <div className='image-about'>
              <img src="https://ik.imagekit.io/99djpd8k3/WhatsApp_Image_2023-03-27_at_11.09.22_PM.jpeg?updatedAt=1679942920224" />
            </div>
            <div className='image-about'>
              <img src="https://ik.imagekit.io/99djpd8k3/WhatsApp_Image_2023-03-26_at_4.38.04_PM.jpeg?updatedAt=1679933434391" />
            </div>
            <div className='image-about'>
              <img src="https://ik.imagekit.io/99djpd8k3/WhatsApp_Image_2023-03-31_at_10.06.24_PM.jpeg?updatedAt=1680281247931" />
            </div>
            <div className='image-about'>
              <img src="https://ik.imagekit.io/99djpd8k3/photo3.jpg?updatedAt=1679933433996" />
            </div>
            {/* <div className='image-about image5'>
              <img src={Image5} />
            </div>
            <div className='image-about'>
              <img src={Image6} />
            </div> */}
          </Slider>
        </div>
      </m.div>
  )
}

export default Achievements;