import Image1 from './../../img/partnership/partnership1.jpg';
import Image2 from './../../img/partnership/partnership2.jpg';
import Image3 from './../../img/partnership/partnership3.jpg';

export default [   {
    id: 6,
    title: "THE SMART GARDEN",
    // period: "1 Maret - 1 Mei 2023",
    Sdesc:"Sparkling Green Smiles",
    link:"https://drive.google.com/file/d/1aoUvCD37oVeS2pdsozV_yWNQzaRc7Z4K/view?usp=sharing",
    link1:"https://drive.google.com/file/d/1_vNh2yYEt3ctyBE9-K2tr5P2-l8m4HBm/view?usp=sharing",
    type: "Beauty",
    web:"https://smart-garden.sweekriti-asset.in/",
    img: "https://ik.imagekit.io/99djpd8k3/Main_Logo.png?updatedAt=1679410290833",
    postimg:"https://ik.imagekit.io/99djpd8k3/posters-1.png?updatedAt=1689848695746",
    postimg1:"https://ik.imagekit.io/99djpd8k3/WhatsApp%20Image%202023-07-20%20at%207.19.49%20PM.jpeg?updatedAt=1689922844514",
    desc: [
        "The happiness to have Greenery all around is a profound character of all humansThe maintenance involved in terms of regular number of hours on daily basis and mismatch between spent money and complicated outputs in terms of Lighted Greenry can be understood with Concrete Jungles acquiring moment now all the more inside homes ,houses or flats",

        "This least Maintainance Smart Gardens ,are also portable -have tanks attached which automatically water their variety of plants,notify the buyer to refill tanks when empty after few weeks,indicate temperature,  have solar operation possibility along with regular operation, turn lights ON with an easy edge to buyers to turn all of these features ON/OFF from any location and see their garden ",


    ]
},
    {
        id: 1,
        title: "JEEVAN",
        // period: "1 Januari - 1 Juli 2023",
        Sdesc:"For step Forward with Saving Water",
        type: "Sparsh",
        img: "https://ik.imagekit.io/99djpd8k3/20230319_163701_0000.png?updatedAt=1679410545547",
        postimg:"https://ik.imagekit.io/99djpd8k3/1_20230322_095949_0000.png?updatedAt=1679463647058",
        desc: [
            "The Water Scarcity in especially less densely populated villages which are 5 lakh in number and where Water department could not reach can be overcome with this model",

            "Automation and signalling for communication is a need in such villages ",

            "The Signals to automatically turn pumps ON till locations where water exists and push water to a location where there is acute water scarcity can touch the lives of present and future generations while giving them solution for their biggest challenge of their lives which seemed without solution before JEEVAN which is simple,easy and minimal cost involving approach",
        
        ]
    },
    {
        id: 2,
        title: "RENEWABLE ECOsystem ",
        // period: "1 Maret - 1 Mei 2023",
        Sdesc:"Beyond just Efficiency",
        type: "Power",
        link:"https://drive.google.com/file/d/1H1lnhhS9nTMp3ntuGOimQBu8bWT5sYZw/view?usp=sharing",
        link1:"https://drive.google.com/file/d/1jXw1kO9znySZ2VjMMrQ393yz4lohJ7th/view?usp=sharing",
        img: "https://ik.imagekit.io/99djpd8k3/RECO.jpg?updatedAt=1679412204155",
        postimg:"https://ik.imagekit.io/99djpd8k3/posters-2.png?updatedAt=1689848696893",
        postimg1:"https://ik.imagekit.io/99djpd8k3/WhatsApp%20Image%202023-07-21%20at%207.59.35%20AM.jpeg?updatedAt=1689922697873",
        desc: [
            "The 0/Zero electricity approach to save the non-reneawable resources of the nation has led to the making of RECO or Renewable Ecosystem.This will involve energy conversion from Sun,Wind while minimum intervention from user as this system runs through Automation.",

            "Commercial hangouts are forced to maintain wall-to-wall green lighting on their property.They must make their hotels, restaurants, and parlours appear appealing to customers.It costs money to pay the electrical bills, and it takes a lot of labour to routinely irrigate their property outside.RECO provides people with relief and helps the country save electricity.",

            "RECO has a solution using only a pair of solar panels and automations.Additionally, the interface for lights going on automatically, water levels lowering in tanks, etc. on personal devices like mobiles makes life easier for individuals in terms of costs and labor-intensive tasks. Those who choose this ecology will feel lighter in both their brains and their wallets.",
        ]
    },
    {
        id: 3,
        title: "JALUPAYE",
        // period: "1 Maret - 1 Mei 2023",
        Sdesc:"One more step with technology for Water",
        type: "Power",
        img: "https://ik.imagekit.io/99djpd8k3/jalupay_1.jpg?updatedAt=1678812283654",
        postimg:"https://ik.imagekit.io/99djpd8k3/posters-3.png?updatedAt=1689848696520",
        desc: [
            "The requirement to keep our internal tanks full is extreme necessity ,even at metro cities and many locations",

            "JALUPAYE will enable filling up of individual's tank as water fails below a set level and keep filling it till the required point of tanks",

            "As tanks empty,notification can be sent to the user and filling of tanks happen Control is possible remotely",
        ]
    },
    {
        id: 4,
        title: "FARM AUTOMATION",
        // period: "1 Maret - 1 Mei 2023",
        Sdesc:"Strengthening Farmers and Strengthening Nation",
        type: "Gaon",
        img: "https://ik.imagekit.io/99djpd8k3/250by233.png?updatedAt=1679411137057",
        postimg:"https://ik.imagekit.io/99djpd8k3/insta_autofarm.png?updatedAt=1679461458164",
        desc: [
            "The number of work hours and physical labour which an Indian Farmer gives to feed the nation is uncreditable and requires attention ",

            "With AUTOFARMS और Farm Automation,the automatic turning ON of irrigation based on soil dryness ,automatic filling of water tanks ,measurement of used water ,monitoring of fields from any location is possible            ",

            "This product will make the requirement of Farmers to be continuously  present on their (खेत )Farms to be very less and they may carry their other businesses well",
        ]
    },
    {
        id: 5,
        title: "Mushroots",
        // period: "1 Maret - 1 Mei 2023",
        Sdesc:"One more step with technology for Growing Mushroom",
        type: "Sparsh",
        web:"https://mushroots.netlify.app",
        link:"https://drive.google.com/file/d/10K0Zu24CtHRqcx2sqLMiAvlKu5ew_SlW/view",
        link1:"",
        img: "https://ik.imagekit.io/99djpd8k3/MUSHROOOTA.png?updatedAt=1679412094293",
        postimg:"https://ik.imagekit.io/99djpd8k3/mushroom%20garden%202.webp?updatedAt=1690606835770",
        postimg1:"https://ik.imagekit.io/99djpd8k3/mushrooms.webp?updatedAt=1690606836159",
        // postimg:"https://upload.wikimedia.org/wikipedia/commons/thumb/7/70/Solid_white.svg/2048px-Solid_white.svg.png",
        desc: [
            "Are you a food lover who is always on the lookout for the freshest and most nutritious ingredients? Look no further than our premium selection of fresh mushrooms! Our mushrooms are carefully grown and harvested to ensure the highest quality and nutritional value.",

            "Not only are mushrooms delicious, they are also packed with health benefits. They are a great source of protein, fiber, and essential vitamins and minerals such as Vitamin D, potassium, and selenium. Studies have also shown that mushrooms may help support immune function, promote heart health, and even reduce the risk of certain types of cancer.",

            "At Mushroots, we are committed to providing our customers with the best possible products and service. Whether you're a home cook, a professional chef, or just someone who enjoys delicious and healthy food, our mushrooms are perfect for salads, soups, stir-fries, and so much more.",
        ]
    },
    {
        id: 7,
        title: "Leap Web",
        // period: "1 Maret - 1 Mei 2023",
        Sdesc:"One more step with technology for Growing Mushroom",
        type: "Sparsh",
        web:"https://leapweb.sweekriti-asset.in",
        link:"https://ik.imagekit.io/99djpd8k3/Leap%20web%20Pricing.png?updatedAt=1708861209267",
        link1:"",
        img: "https://ik.imagekit.io/99djpd8k3/Leap%20Web.png?updatedAt=1708860317967",
        postimg:"https://ik.imagekit.io/99djpd8k3/leap%20web%20poster.jpeg?updatedAt=1708860901329",
        postimg1:"https://ik.imagekit.io/99djpd8k3/Leap%20web%20Pricing.png?updatedAt=1708861209267",
        // postimg:"https://upload.wikimedia.org/wikipedia/commons/thumb/7/70/Solid_white.svg/2048px-Solid_white.svg.png",
        desc: [
           "Welcome to Leap Web, a subsidiary of Sweekriti Asset LLP, dedicated to empowering individuals with the skills and knowledge needed to thrive in the digital landscape. Our mission is simple: to provide comprehensive training and resources for students, young professionals, and anyone seeking to enhance their earning potential.",
           "From social media marketing to website development and freelancing opportunities, we offer practical, hands-on learning experiences tailored to meet the demands of today's dynamic market. Join us as we leap forward together towards a brighter, more prosperous future. At Leap Web, we understand the importance of staying ahead in a rapidly evolving digital world.",
           "That's why our expert instructors are committed to delivering cutting-edge training programs designed to unlock your full potential. Whether you're looking to launch a successful career in digital marketing or build your own thriving freelance business, we provide the tools and guidance you need to succeed. With our proven track record of empowering individuals to achieve their goals, Leap Web is your trusted partner on the path to success."
        ]
    },
];
