import React from 'react';
import './certificate.css';

// Framer Motion
import { motion as m } from 'framer-motion';

// Data
import CertificateData from './CertificateData';

// React Router
import { NavLink } from 'react-router-dom';

const Certificate = () => {
    return (
        <m.div
            className='partnership'
            initial={{ y: "25%", opacity: 0 }}
            animate={{ y: "0%", opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 1, ease: "linear" }}
        >
          
            {/* <p className="desc-partnership">The technology ,youth,energy,expertise have combined at Sweekriti Asset to extend solutions and Invitations to every one ,for everyone.
Welcoming everyone to have a look on our products.</p> */}

            <div className='row-partnership1'>
                {CertificateData.map((item, index) => (
            
                        <div className='thumb-image-partnership card-partnership1'>
                            <img src={item.img} alt="image-partnership" />
                            <h3 className='title-partnership'>{item.Sdesc}</h3>
                     
                    </div>
                ))}
            </div>
            
        </m.div>
        
    )
}

export default Certificate;