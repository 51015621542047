import React from 'react';
import './cases.css';

// Components
import CaseItems from './../caseitems/CaseItems';


const Cases = () => {
    return (
        <div className="cases">
            <div className="cases-heading">
                <h3 style={{color:"orange",fontWeight:"bolder"}}>Products Of Sweekriti Asset</h3>
                <p>The technology ,youth,energy,expertise have combined at Sweekriti Asset to extend solutions and Invitations to every one ,for everyone.
Welcoming everyone to have a look on our products.</p>
            </div>

            <div className="row-cases">
                <CaseItems />
            </div>
        </div>
    )
}

export default Cases;