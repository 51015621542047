import React from 'react'
import './hero.css'

// Framer Motion
import {motion as m} from 'framer-motion';

// Image
import ImageHero from './../../img/hero/image-hero.png';

// Router
import { NavLink } from 'react-router-dom';

const Hero = () => {
    return (
        <div className="hero">
            <m.div 
                className="hero-image"
                initial={{x: "-25%", opacity: 0}}
                animate={{x: "0%", opacity: 1}}
                exit={{opacity: 0}}
                transition={{duration: 1, ease: "easeOut"}}
            >
                <img src="https://ik.imagekit.io/99djpd8k3/image-hero.a235ca8238e72d05b2a9.png?updatedAt=1680365606958" alt="" />
            </m.div>

            <m.div
                className="hero-desc"
                initial={{x: "25%", opacity: 0}}
                animate={{x: "0%", opacity: 1}}
                exit={{opacity: 0}}
                transition={{duration: 1, ease: "easeOut"}}
            >
                <h3 >IF YOU THINK- YOU CAN... <br/><h2 > THEN TOGETHER WE WILL!</h2></h3>

                <p>Sweekriti Asset aims to accept all the ideas,concepts, people, entities who are left alone/unattended or unattempted on grounds of being different,difficult,unapproachable or non-acceptable.</p>

                <NavLink style={{background:"#FF9A33",color:"white",fontWeight:"bolder"}} to="/about">About Us</NavLink>
            </m.div>
        </div>
    )
}

export default Hero;