import React from 'react';
import './products.css';

// Framer Motion
import { motion as m } from 'framer-motion';

// Data
import dataProducts from './dataProducts';
import dataProducts1 from './dataProducts1';
// React Router
import { NavLink } from 'react-router-dom';

const Products = () => {
    return (
        <m.div
            className='partnership'
            initial={{ y: "25%", opacity: 0 }}
            animate={{ y: "0%", opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 1, ease: "linear" }}
        >
            <h3 className='title-partnership'>Products Of Sweekriti Asset</h3>
            <p className="desc-partnership">The technology ,youth,energy,expertise have combined at Sweekriti Asset to extend solutions and Invitations to every one ,for everyone.
Welcoming everyone to have a look on our products.</p>

            <div className='row-partnership'>
                {dataProducts.map((item, index) => (
                    <div className='card-partnership' key={index}>
                        <div className='thumb-image-partnership'>
                            <img src={item.img} alt="image-partnership" />
                        </div>
                        <div className='desc-card-partnership'>
                            <h3>{item.title}</h3>
                            <p><span>{item.type}</span> | {item.period}</p>
                            <h5>{item.Sdesc}</h5>
                           <h6><NavLink to={`${item.id}`}>Know More</NavLink></h6> 
                        </div>
                    </div>
                ))}
            </div>
            {/* <h3 style={{marginTop:"40px"}} className='title-partnership'>Brochure and Booklet of Products of Sweekriti Asset</h3> */}
            {/* <p className="desc-partnership">The technology ,youth,energy,expertise have combined at Sweekriti Asset to extend solutions and Invitations to every one ,for everyone.
Welcoming everyone to have a look on our products.</p> */}

            {/* <div style={{marginTop:"20px"}} className='row-partnership'>
                {dataProducts1.map((item, index) => (
                    <div className='card-partnership' key={index}>
                        <div className='thumb-image-partnership'>
                            <img src={item.img} alt="image-partnership" />
                        </div>
                        <div className='desc-card-partnership'>
                            <h3>{item.title}</h3>
                            <p><span>{item.type}</span> | {item.period}</p>
                            <h3>{item.Sdesc}</h3>
                            
                           
                            <NavLink style={{color:"orange",marginLeft:"5px",fontSize:"0.88rem"}} to={item.link}>View Booklet </NavLink>
                        <NavLink style={{color:"orange",marginLeft:"20px",fontSize:"0.88rem"}} to={item.link1}>View Brochure</NavLink>
                           
                    
                        </div>

                    </div>
                ))}
            </div> */}
        </m.div>
        
    )
}

export default Products;