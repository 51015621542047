import React from 'react';
import './reviews.css';

const Reviews = () => {
    return (
        <div className="reviews">
            <div className="reviews-col-1">
                <h3>15+</h3>
                <p>Products</p>
            </div>
            <div className="reviews-col-2">
                <h3>Zonal Qualifiers-Eureka 2023</h3>
                <p>IIT-Bombay(600 out of 17000 plus startups)</p>
            </div>
            <div className="reviews-col-3">
                <h3>Launching</h3>
                <p>HoTs-Hands on Training</p>
            </div>
            <div className="reviews-col-3">
                <h3>Supported at</h3>
                <p>Eureka-2022 By IIT-Bombay</p>
            </div>
        </div>
    )
}

export default Reviews;
