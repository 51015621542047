import React from 'react';
import './whyus.css';

// Image
import Whyus1 from './../../img/why-us/1.jpg';
import Whyus2 from './../../img/why-us/2.jpg';
import Whyus3 from './../../img/why-us/3.jpg';

const Whyus = () => {
    return (
        <div className="whyus">
            <h3 className='whyus-title'>Sections Of Sweekriti Asset </h3>

            <div className="row-whyus">
                <div className="whyus-card">
                    <div className="thumb-whyus-image">
                        <img src="https://ik.imagekit.io/99djpd8k3/POWER-removebg-preview.png?ik-sdk-version=javascript-1.4.3&updatedAt=1670657310227" alt="" />
                    </div>
                    <div className="desc-card">
                        <h3>POWER-Pulling Output With Electrons Rigorously (To maximize outputs especially in case of High Power-Kilowatt-kW & beyond)</h3>
                        <p className='desc-card1'>For centuries, scientists have been fascinated by the mysteries of electron emission, generation, and propagation. This division of research is dedicated to understanding these phenomena and developing new technologies that maximize their outputs. By studying the properties of electrons, researchers are able to create new technologies that can be used in a variety of applications. Through segregation of ideas,experiments,theory,understanding,analysis , this section of research will help us to unlock the secrets of electron emission, generation, and propagation so that we can make use of them in our everyday lives ,also for strategic applications  and this  will rightly come out of this section.
                       </p>
                    </div>
                </div>
                <div className="whyus-card col-2">
                    <div className="thumb-whyus-image">
                        <img src="https://ik.imagekit.io/99djpd8k3/Beauty-removebg-preview.png?ik-sdk-version=javascript-1.4.3&updatedAt=1670657516048" alt="" />
                    </div>
                    <div className="desc-card">
                        <h3>Beauty- Bold Elegant Attitude United Together is You (Designing and Development of products only for Women)</h3>
                        <p className='desc-card1'>The Sex Ratio across globally and in India are indicative on the unacceptability of Women ,the pertaining crime rate against women are just few of many clear parameters on the need of support and help which women need on an extensive scale.
The "Empowerment" will come by giving technology and introducing logics to women ,the enhanced ability to analyse and question.The introduction of technical products,their use and generating products by using technology backed up devices and products shall give not only more money but independence,a healthy family and ecosystem all around for Women.We vision that Women support technology,use technology,design products easily with technology , machines,electronics and cyber physical systems.
</p>
                    </div>
                </div>
                <div className="whyus-card">
                    <div className="thumb-whyus-image">
                        <img src="https://ik.imagekit.io/99djpd8k3/GAON-removebg-preview.png?ik-sdk-version=javascript-1.4.3&updatedAt=1670657727143" alt="" />
                    </div>
                    <div className="desc-card">
                        <h3>GAON-Greentechnologies Advancedunit Of Nation (Supporting Gap between existing green technologies and implementation)</h3>
                        <p className='desc-card1'>Providing basic amenities to the large population especially in villages will touch the lives of every individual of this generation and also the coming generations .This should and will be the plinth for an Atmanirbhar Bharat in true senses.
The problem of exhaustion of nonrenewable sources of energy - in many cases and the incompetency to be able to adhere the basic needs water and electricity of citizens of our nation, the usage of Green technology needs attention.
The work on Solar ,Wind , Water,Rain Water  may be put up collectively in this advanced unit with a common motive to maintain and smoothly run and make Green Technologies functional as much as possible.
</p>
                    </div>
                </div>
                <div className="whyus-card col-2">
                    <div className="thumb-whyus-image">
                        <img src="https://ik.imagekit.io/99djpd8k3/SPARSH-removebg-preview.png?ik-sdk-version=javascript-1.4.3&updatedAt=1670658091812" alt="" />
                    </div>
                    <div className="desc-card">
                        <h3>Sparsh - Skillful People After Real Specific Help (For internships and Hands on Training-HoT minimizing gap between academics and Industry)</h3>
                        <p className='desc-card1'>Internship opportunities and Hands On Training or HoT shall be a full proof solution of individuals  for the final way of any solution in the form of product.With regular procedural exercise,done periodically-a self sustainable,confident,independent individual may develop which will target many similar like him/herself.  Through various cycles of TASK- To Acquire Skill & Knowledge,we may be able to train and develop the actual developers from the community /part of society for whom still technology remains  an unapproachable world. The aim of this section is to touch different groups of society/individuals to develop our  ideas or products and  make them our TASK FORCE. </p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Whyus;
