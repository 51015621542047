import Image1 from './../../img/partnership/partnership1.jpg';
import Image2 from './../../img/partnership/partnership2.jpg';
import Image3 from './../../img/partnership/partnership3.jpg';

export default [
    {
        id: 1,
        Sdesc:"Ministry Of Corporate Affairs ",
        img: "https://ik.imagekit.io/99djpd8k3/Ministry_Of_Corporate_Affairs_-_MCA_Services_page-0001.jpg?updatedAt=1679500868926",
     
    },
    {
        id: 2,
        Sdesc:"Certificate of LLP Incorporation",
        img: "https://ik.imagekit.io/99djpd8k3/Certificate_of_LLP_Incorporation_Sweekriti_page-0001.jpg?updatedAt=1679500858131",
     
    },
    {
        id: 2,
        Sdesc:"Start Up_DPIIT",
        img: "https://ik.imagekit.io/99djpd8k3/DIPP139781_SWEEKRITI_ASSET_LLP_RECOGNITION_8807526895935679320-1.png?updatedAt=1689922183002",
     
    },
];